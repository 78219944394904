import React from 'react'
import Header from './header'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className="text-black dark:bg-black dark:text-white flex flex-col h-screen">
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <main className="mt-16">{children}</main>
  </div>
)

export default Layout
