import {Link} from 'gatsby'
import React, { useState, useRef } from 'react'

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <nav className={`bg-white dark:bg-gray-800 shadow top-0 z-20 w-full fixed`}>
        <div className='max-w-7xl mx-auto px-8'>
          <div className='flex items-center justify-between h-16'>
            <div className="w-full justify-between flex items-center">
                {/* <Link to='/' className="flex-shrink-0">
                    <img className="h-7" src={'/logo.png'} alt="rijad.dev"/>
                </Link> */}
                <Link
                  className="font-bebas-neue uppercase text-xl sm:text-2xl font-black flex flex-col leading-none dark:text-white text-gray-800 hover:text-blue-500"
                  to="/"
                >
                  <span className="text-inverted ml-4">rijad.dev</span>
                </Link>
                <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                        <Link to='/' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
                          Home
                        </Link>
                        <Link to='/about' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
                          About
                        </Link>
                        <Link to='/projects' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
                          Projects
                        </Link>
                        <Link to='/blog' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
                          Blog
                        </Link>
                        <Link to='/contact' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
                          Contact
                        </Link>
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="ml-4 flex items-center md:ml-6">
                </div>
            </div>
            <div className="-mr-2 flex md:hidden z-20">
                <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-900 dark:text-white hover:text-gray-800 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                >
                    <svg width="20" height="20" fill="currentColor" className="h-8 w-8" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                        </path>
                    </svg>
                </button>
            </div>
          </div>
        </div>
        {(isOpen) && (
        <div className="md:hidden top-15 left-15 w-full absolute z-30 shadow bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link to='/' className="navigation text-gray-600 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium">
                    Home
                </Link>
                <Link to='/about' className="navigation text-gray-600 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium">
                    About
                </Link>
                <Link to='/projects' className="navigation text-gray-600 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium">
                    Projects
                </Link>
                <Link to='/blog' className="navigation text-gray-600 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium">
                    Blog
                </Link>
                <Link to='/contact' className="navigation text-gray-600 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium">
                    Contact
                </Link>
            </div>
        </div>
        )}
      </nav>
    </>
  )
};
export default Header
